import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import privilegeSaga from "./privilege/saga";
import campaignsSaga from "./campaigns/saga";
import CouponTransactionSaga from "./coupontransaction/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    ...authSaga,
    ...privilegeSaga,
    ...campaignsSaga,
    ...CouponTransactionSaga,
  ]);
}
