import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Alert, Button, Col, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { checkCouponWithGsm } from "src/store/campaigns/saga";
import { CouponCampaignDto } from "src/store/campaigns/type";

const CouponCheckWithGsm = ({
  setCampaing,
  setValues,
}: {
  setValues: (data: { code: string; gsm: string }) => void;
  setCampaing: (campaing: CouponCampaignDto) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  return (
    <React.Fragment>
      <div className="d-flex  flex-column align-items-center">
        <h2>Kupon Doğrulama</h2>
        <div className=" text-muted text-center">
          Kupon kodu ve plaka bilgisini girerek kupon durumunu sorgulayabilir.{" "}
          <br />
          Kampanya bilgilerine ulaşabilirsiniz.
        </div>
        <Formik
          onSubmit={values => {
            setValues(values);
            setError("");
            dispatch(
              checkCouponWithGsm({
                payload: {
                  onSuccess: (ms, payload) => {
                    setCampaing(payload);
                  },
                  onError: ms => {
                    setError(ms);
                  },
                },
                body: values,
              })
            );
          }}
          initialValues={{
            code: "",
            gsm: "",
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Row>
                {error && (
                  <Col md={12}>
                    <Alert color="danger">{t(error)}</Alert>
                  </Col>
                )}
                <GetInput
                  inputprop={{
                    col: 12,
                    field: "code",
                    label: "Kod",
                  }}
                />
                <GetInput
                  inputprop={{
                    col: 12,
                    field: "gsm",
                    label: "Gsm",
                  }}
                />
                <Col md={12}>
                  <Button
                    className="w-100"
                    color="success"
                    type="submit"
                    onClick={() => handleSubmit()}
                  >
                    {"Doğrula"}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CouponCheckWithGsm;
