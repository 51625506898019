import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import Loader from "../Loader";

const CancelAlert = ({
  onConfirm,
  onCancel,
  message,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}) => {
  const loading = useSelector(isLoading);
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <SweetAlert
          btnSize="md"
          showCancel
          title=""
          confirmBtnText={t("Yes")}
          cancelBtnCssClass={"cancel-button"}
          confirmBtnCssClass={"confirm-button"}
          cancelBtnText={t("No")}
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="success"
          onConfirm={() => onConfirm()}
          onCancel={() => onCancel()}
        >
          <p className="fw-bold">{message}</p>
        </SweetAlert>
      )}
    </>
  );
};
export default CancelAlert;
