import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CouponCampaignDto } from "src/store/campaigns/type";

const PrivilegeCard = ({ campaign }: { campaign: CouponCampaignDto }) => {
  const { t } = useTranslation();
  const table = [
    {
      label: t("Title"),
      value: campaign.Privilege.Title,
    },
    {
      label: t("Short Description"),
      value: campaign.Privilege.ShortDescription,
    },
    {
      label: t("Description"),
      value: campaign.Privilege.Description,
    },
    {
      label: t("Category"),
      value: campaign.Privilege.PrivilegeCategoryName,
    },
    {
      label: t("Usage Type"),
      value: campaign.Privilege.PrivilegeUsageType,
    },
    {
      label: t("Discount Type"),
      value: campaign.Privilege.DiscountType,
    },
    {
      label: t("Discount Value"),
      value: campaign.Privilege.DiscountValue,
    },
    {
      label: t("Coupon Count"),
      value: campaign.Privilege.CouponCount,
    },
    {
      label: t("Discount Value"),
      value: campaign.Privilege.DiscountValue,
    },
    {
      label: t("Terms Of Service"),
      value: campaign.Privilege.TermsOfService,
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Ayrıcalık Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default PrivilegeCard;
