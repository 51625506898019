import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CouponCampaignDto } from "src/store/campaigns/type";

const VehicleCard = ({ campaign }: { campaign: CouponCampaignDto }) => {
  // const getIcon = (condition: boolean) => {
  //   return condition ? (
  //     <i className="alert-success icon-container text-success bx bx-check" />
  //   ) : (
  //     <i className="alert-danger icon-container text-danger  bx bx-x      " />
  //   );
  // };

  const { t } = useTranslation();
  const table = [
    {
      label: t("Plate"),
      value: campaign.Vehicle.Plate,
    },
    {
      label: t("Brand"),
      value: campaign.Vehicle.make,
    },
    {
      label: t("Model"),
      value: campaign.Vehicle.model,
    },
    {
      label: t("Registration Date"),
      value: campaign.Vehicle.RegistrationDate
        ? moment(campaign.Vehicle.RegistrationDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      label: t("Color"),
      value: campaign.Vehicle.VehicleColorName,
    },
    {
      label: t("Body"),
      value: campaign.Vehicle.body,
    },
    {
      label: t("Chassis Number"),
      value: campaign.Vehicle.ChassisNumber,
    },
    {
      label: t("Body Type"),
      value: campaign.Vehicle.BodyTypeName,
    },
    {
      label: t("Fuel Type"),
      value: campaign.Vehicle.FuelTypeName,
    },
    {
      label: t("Gear Type"),
      value: campaign.Vehicle.GearTypeName,
    },
    {
      label: t("Engine"),
      value: campaign.Vehicle.engine,
    },
    {
      label: t("Engine Number"),
      value: campaign.Vehicle.EngineNumber,
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Araç Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default VehicleCard;
