import { ApiCallback } from "../base/types/saga";

export type LoginInputs = {
  token: string;
};
export interface FaqsDto {
  Id: string;
  Question: string;
  Answer: string;
}
export interface LoginResponseModel {
  SupplierLocationDto: SupplierLocationDto;
  AccessToken: AccessToken;
  Roles: string[];
}

export interface AccessToken {
  Token: string;
  Expiration: string;
}

export interface SupplierLocationDto {
  Id: string;
  SupplierId: string;
  Name: string;
  TaxNumber?: any;
  TaxOffice?: any;
  Address: string;
  CityId: string;
  CityName: string;
  TownId: string;
  TownName: string;
  PersonName?: any;
  PersonSurname?: any;
  BankName?: any;
  Iban?: any;
  Email: string;
  Active: boolean;
  Brand?: any;
  Logo?: any;
  ContractStartDate?: any;
  ContractEndDate?: any;
}

export interface LoginInputParams extends ApiCallback<LoginResponseModel> {
  params: LoginInputs;
}

export interface RegisterDto {
  email: string;
  password: string;
  phone: string;
  name: string;
  surname: string;
  deviceId?: string;
  signedClientContracts?: SignedClientContractDto[];
}

export interface SignedClientContractDto {
  clientContractId: string;
}

export interface SignedClientContract {
  ClientContractId: string;
  ClientContractTitle: string;
  SignedDate: string;
  SignedIpAddress: string;
  HardCopyLocation?: any;
  Id: string;
}
export interface PackagePriceDto {
  LocationPrice: number;
  Products10: number;
  Products20: number;
  Products30: number;
  Products40: number;
  Products50: number;
}

export class RoleConsts {
  static Supplier = "supplier";
  static Yoyo = "yoyoservice";
}
