import produce from "immer";
import { createAction } from "redux-smart-actions";
import { createSelector } from "reselect";
import { KomutRedux } from "../base/types/KomutRedux";
import { RootState } from "../base/types/RootState";
import { LoginResponseModel } from "./types";

const LOGOUT_ACTION = "LOGOUT_ACTION";
const SET_USER = "SET_USER";

export interface AuthReducer {
  user?: LoginResponseModel;
}

export interface AuthActions {
  logoutAction: any;
  setUser: any;
}

const auth = new KomutRedux<AuthReducer, AuthActions>();

auth.setInitialState({
  user: undefined,
});

auth.setActions({
  logoutAction: createAction(LOGOUT_ACTION),
  setUser: createAction(SET_USER, (payload: LoginResponseModel) => ({
    payload,
  })),
});

auth.setRedusers(
  produce((draft: AuthReducer, action: any) => {
    switch (action.type) {
      case SET_USER:
        draft.user = action.payload;
        break;
      case LOGOUT_ACTION:
        draft.user = undefined;
        break;
    }
  }, auth.initialState)
);

// ** SELECTORS *** \\
const userState = (state: RootState) => state.auth.user;
export const userSelector = createSelector([userState], user => user);

const userTokenState = (state: RootState) => state.auth.user?.AccessToken.Token;
export const getUserToken = createSelector([userTokenState], token => token);
const localeState = (state: RootState) => state.language.language.Code;
export const localeSelector = createSelector([localeState], lang => lang);

export const getApiParams = createSelector(
  [localeState, userTokenState],
  (lang, token) => {
    return {
      lang,
      token: token || "",
    };
  }
);

export default auth;
