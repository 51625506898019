const images = {
  logo: require("./logo.svg").default,
  logopng: require("./logo.png").default,
  click: require("./click.svg").default,
  doc: require("./doc.png").default,
  avatar: require("./avatar.png").default,
  cardchip: require("./cardchip.png").default,
  yellowcheck: require("./yellowcheck.png").default,
  excelicon: require("./excelicon.png").default,
};

export default images;
