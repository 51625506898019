import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import Loader from "src/components/Loader";
import { CouponCampaignDto } from "src/store/campaigns/type";
import { isLoading } from "src/store/loader";
import CouponCheck from "./couponCheck";
import UseCoupon from "./useCampaign";
import { userSelector } from "src/store/auth";
import { RoleConsts } from "src/store/auth/types";
import UseCouponWithGsm from "./useCampaignwithgsm";
import CouponCheckWithGsm from "./couponCheckwithgsm";

const CouponCheckPage = () => {
  const user = useSelector(userSelector);
  const [campaign, setCampaing] = useState<CouponCampaignDto>();
  const [values, setValues] = useState<{ code: string; plate: string }>();
  const [valueswithgsm, setValueswithgsm] = useState<{
    code: string;
    gsm: string;
  }>();
  const loader = useSelector(isLoading);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Kupon Doğrulama | Servis Plan Tedarikçi Portalı</title>
        </MetaTags>
        <Container fluid className="mt-5">
          {loader && <Loader />}
          {user && user.Roles.some(x => x === RoleConsts.Yoyo) ? (
            <>
              {valueswithgsm && campaign ? (
                <UseCouponWithGsm
                  setCampaing={setCampaing}
                  setValues={setValueswithgsm}
                  dataValues={valueswithgsm}
                  campaign={campaign}
                />
              ) : (
                <CouponCheckWithGsm
                  setCampaing={setCampaing}
                  setValues={setValueswithgsm}
                />
              )}
            </>
          ) : (
            <>
              {values && campaign ? (
                <UseCoupon
                  setCampaing={setCampaing}
                  setValues={setValues}
                  dataValues={values}
                  campaign={campaign}
                />
              ) : (
                <CouponCheck setCampaing={setCampaing} setValues={setValues} />
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponCheckPage;
