import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CouponCampaignDto } from "src/store/campaigns/type";

const CustomerCard = ({ campaign }: { campaign: CouponCampaignDto }) => {
  const { t } = useTranslation();
  const table = [
    {
      label: t("Identity"),
      value: campaign.Customer.IdentityNumber,
    },
    {
      label: t("Name"),
      value: campaign.Customer.Name,
    },
    {
      label: t("Surname"),
      value: campaign.Customer.Surname,
    },
    {
      label: t("Email"),
      value: campaign.Customer.Email,
    },
    {
      label: t("Phone"),
      value: campaign.Customer.PhoneNumber ?? "-",
    },

    {
      label: t("Gender"),
      value: campaign.Customer.GenderName ?? "-",
    },
    {
      label: t("Job"),
      value: campaign.Customer.JobName ?? "-",
    },
    {
      label: t("Education Status"),
      value: campaign.Customer.EducationStatusName ?? "-",
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Müşteri Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CustomerCard;
