import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateCouponTransaction } from "src/store/coupontransaction/saga";
import { CouponTransactionDto } from "src/store/coupontransaction/type";
import * as Yup from "yup";

const EditCouponTransaction = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: CouponTransactionDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit Coupon Transaction"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          id: data.Id,
          description: data.Description,
          invoiceDate: data.InvoiceDate ?? "-",
          invoiceNumber: data.InvoiceNumber ?? "-",
          invoicePrice: data.InvoicePrice ?? "-",
        }}
        onSubmit={values => {
          dispatch(
            updateCouponTransaction({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          description: Yup.string().required(t("Required")),
          invoiceDate: Yup.string().when("invoice", {
            is: (invoice: boolean) => invoice,
            then: Yup.string().required(t("Required")),
          }),
          invoiceNumber: Yup.string().when("invoice", {
            is: (invoice: boolean) => invoice,
            then: Yup.string().required(t("Required")),
          }),
          invoicePrice: Yup.number().when("invoice", {
            is: (invoice: boolean) => invoice,
            then: Yup.string().required(t("Required")),
          }),
        })}
        inputs={[
          {
            field: "description",
            label: t("Description"),
            inputType: InputType.multilinetext,
          },

          {
            field: "invoiceNumber",
            label: t("Invoice Number"),
          },
          {
            field: "invoiceDate",
            label: t("Invoice Date"),
            inputType: InputType.datetime,
          },
          {
            field: "invoicePrice",
            label: t("Invoice Price"),
            inputType: InputType.number,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditCouponTransaction;
