import TextEditor from "../HtmlEditor";
import { Props } from "../type";

const CustomEditor = ({
  label,
  error,
  type = "text",
  field,
  value,
  touched,
  onChange,
  onBlur,
  disabled,
}: Props) => {
  return (
    <div className="textOnInput my-2" style={{ paddingBlockEnd: 20 }}>
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
      >
        {label ? label : ""}
      </label>
      <TextEditor
        className={
          error && touched
            ? "errorInput defaultInput form-control form2-control"
            : "defaultInput form-control form2-control"
        }
        value={value}
        setFieldValue={(e: any) => onChange && onChange(e)}
      />

      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomEditor;
