import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

import "rsuite/dist/rsuite.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-drawer/lib/react-drawer.css";
import "flatpickr/dist/themes/material_blue.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { ToastContainer } from "react-toastify";
const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));
  // useEffect(() => {
  //   user &&
  //     dispatch(
  //       getActiveUser({
  //         payload: {
  //           onSuccess: (message, payload) => {
  //             dispatch(auth.actions.setUser(payload));
  //           },
  //           onError: () => {},
  //         },
  //       })
  //     ); // eslint-disable-next-line
  // }, [dispatch]);
  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <ToastContainer position="top-center" autoClose={1000} />

        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              roles={route.roles}
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
