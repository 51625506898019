import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch } from "react-redux";
import { Pagination } from "rsuite";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { replaceAt } from "src/helpers/replaceAt";
import { DataTableWithPaginationProps } from "./type";
import Breadcrumbs from "../Common/Breadcrumb";

const DataTableWithPagination = ({
  columns,
  listData,
  setDataList,
  title,
  takeValue,
  buttons,
  hideExcel = false,
  component,
  hideRefresh = false,
  action,
  HideBreadcrumb = false,
  paramQuery,
  create,
  paramsid,
  addTitle,
}: DataTableWithPaginationProps) => {
  const { t } = useTranslation();
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const currentSearch = location.search;
  const [totalPage, setTotalPage] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const searchTake = searchParams.get("take");
  const searchPage = searchParams.get("page");
  const history = useHistory();
  const { Id }: { Id: string } = useParams();
  const [page, setPage] = useState(
    searchPage !== null ? parseInt(searchPage) : 1
  ); //eslint-disable-next-line
  const [take, setTake] = useState(
    searchTake !== null ? parseInt(searchTake) : takeValue ? takeValue : 10
  );
  useEffect(() => {
    setPage(1);
  }, [Id]);
  useEffect(() => {
    setLoader(true);
    if (!currentSearch) {
      history.push("?page=" + page + "&take=" + take);
    } else {
      if (currentSearch.includes("?page=")) {
        let query = replaceAt(currentSearch, 6, page);
        history.push(query);
      } else {
        history.push(
          "?page=" + page + "&take=" + take + currentSearch.replace("?", "&")
        );
      }
    }
    dispatch(
      action({
        payload: {
          //@ts-ignore
          onSuccess: (message, payload, details) => {
            setDataList(payload);
            setTotalPage(details.TotalCount / details.Take);
            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: paramsid,
        params: paramQuery
          ? `?Skip=${(page - 1) * take}&Take=${take}&${paramQuery}`
          : `?Skip=${(page - 1) * take}&Take=${take}`,
      })
    ); // eslint-disable-next-line
  }, [page, Id]);

  return (
    <React.Fragment>
      <ToolkitProvider
        keyField="id"
        data={listData}
        columns={columns}
        bootstrap4
        search
        columnToggle
        exportCSV
      >
        {toolkitProps => (
          <React.Fragment>
            <div
              className={
                "d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              {HideBreadcrumb && title && (
                <h5 className="mb-0 font-size-18">{title}</h5>
              )}
              {!HideBreadcrumb && title && (
                <Breadcrumbs title={t("Dashboard")} breadcrumbItem={title} />
              )}
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div className="search-box ms-2  d-inline-block ">
                  <div className="position-relative">
                    <SearchBar {...toolkitProps.searchProps} />
                    <i className="bx bx-search-alt search-icon-search" />
                  </div>
                </div>

                <div style={{ zIndex: 2 }}>
                  {!hideExcel && (
                    <button className="btn btn-info me-1">
                      <i className="mdi mdi-microsoft-excel "></i>
                    </button>
                  )}
                  {!hideRefresh && (
                    <button
                      className="btn btn-warning  me-1"
                      onClick={() => {
                        setLoader(true);
                        dispatch(
                          action({
                            payload: {
                              //@ts-ignore
                              onSuccess: (
                                //@ts-ignore
                                message,
                                //@ts-ignore
                                payload,
                                //@ts-ignore
                                details
                              ) => {
                                toast.info("Yenilendi", {
                                  theme: "colored",
                                });
                                setDataList(payload);
                                setTotalPage(details.TotalCount / details.Take);
                                setLoader(false);
                              },
                              onError: () => {
                                setLoader(false);
                              },
                            },
                            id: paramsid,
                            params: paramQuery
                              ? `?Skip=${
                                  (page - 1) * take
                                }&Take=${take}&${paramQuery}`
                              : `?Skip=${(page - 1) * take}&Take=${take}`,
                          })
                        ); // eslint-disable-next-line
                      }}
                    >
                      <i className="mdi mdi-refresh me-1"></i>
                    </button>
                  )}
                  {buttons}
                  <>
                    {create && (
                      <>
                        {create.createType === "drawer" ? (
                          <button
                            onClick={() => {
                              create &&
                                create.onCreateClick &&
                                create.onCreateClick();
                            }}
                            className="btn btn-success text-light"
                          >
                            <i className="bx bx-plus me-1"></i>{" "}
                            {addTitle ? addTitle : t("New")}
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              history.push(create?.createLink ?? "/")
                            }
                            className="btn btn-success text-light"
                          >
                            <i className="bx bx-plus me-1"></i>
                            {addTitle ? addTitle : t("New")}
                          </button>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
            {component}
            <Row className="pt-4">
              <Col xl="12">
                <div className="table-responsive">
                  {loader && <Loader />}
                  <BootstrapTable
                    {...toolkitProps.baseProps}
                    classes={
                      "table align-middle table-nowrap table-hover table-header-colored"
                    }
                    bordered={false}
                    striped={false}
                  ></BootstrapTable>
                </div>
              </Col>
            </Row>
            <Row className="align-items-md-center mt-30">
              <Col className="pagination pagination-rounded justify-content-end mb-2">
                <Pagination
                  className="data-table-pagination"
                  prev
                  next
                  size="xs"
                  total={totalPage}
                  limit={1}
                  activePage={page}
                  onChangePage={page => setPage(page)}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  );
};

export default DataTableWithPagination;
