import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import MainPage from "src/components/PageContent/MainPage";
import EditCouponTransaction from "./edit";
import CreateCouponTransaction from "./create";
import { CouponTransactionDto } from "src/store/coupontransaction/type";
import { getCouponTransactionList } from "src/store/coupontransaction/saga";
import { useParams } from "react-router";
import { getLocalDate } from "src/helpers/getLocalDate";
import { CampaignCodeDto } from "src/store/campaigns/type";
import { getCouponDetails } from "src/store/campaigns/saga";

const CouponTransactionPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<CouponTransactionDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<CouponTransactionDto[]>([]);
  const [couponData, setCouponData] = useState<CampaignCodeDto>();
  useEffect(() => {
    dispatch(
      getCouponDetails({
        payload: {
          onSuccess: (ms, payload) => {
            return setCouponData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Privilege"),
      dataField: "PrivilegeName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },
    {
      text: t("Supplier Service Type Name"),
      dataField: "SupplierServiceTypeName",
    },
    {
      text: t("City Town"),
      dataField: "CityName",
      formatter: (cellContent: any, data: any) =>
        `${data.TownName}/${cellContent}`,
    },
    {
      text: t("Description"),
      dataField: "Description",
    },
    {
      text: t("Invoice Number"),
      dataField: "InvoiceNumber",
    },
    {
      text: t("Invoice Date"),
      dataField: "InvoiceDate",
      formatter: (cellContent: any) => getLocalDate(cellContent),
    },
    {
      text: t("Transaction Date"),
      dataField: "TransactionDate",
      formatter: (cellContent: any) => getLocalDate(cellContent),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              setSelected(data);
              return setShowEdit(true);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getCouponTransactionList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditCouponTransaction
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateCouponTransaction
        id={id}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />

      {couponData && (
        <MainPage
          title={
            "Qr Code:" + couponData.QRCode + " " + t("Coupon Transactions")
          }
          gridProps={{
            listData: data,
            columns: columns,
            create: {
              createType: "drawer",
              onCreateClick() {
                setShowCreate(true);
              },
            },
            setDataList: setData,
            action: getCouponTransactionList,
            paramsid: id,
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CouponTransactionPage;
