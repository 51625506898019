import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeDto } from "./type";

export const getPrivilegeList = createAction(
  "GET_PRIvilege_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeDto[]>) => ({
    payload,
    url: "/api/supplierportals/privileges" + (params ? params : ""),
  })
);
const opportunitySaga = [
  takeLatest(
    getPrivilegeList.toString(),
    (payload: SagaGenericParams<PrivilegeDto[]>) =>
      getListSaga<PrivilegeDto[]>(payload)
  ),
];

export default opportunitySaga;
