import { Formik } from "formik";
import { Button, Row } from "reactstrap";
import { CustomFormikProps } from "../type";
import MultiLanguageInput from "../Input/MultiLanguage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import GetInput from "../GetInput";

const CustomFormik = ({
  inputs,
  multilanguageinputs,
  validationSchema,
  onSubmit,
  onHide,
  loader,
  initialValues,
}: CustomFormikProps) => {
  const { t } = useTranslation();
  const loading = useSelector(isLoading);
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({
        touched,
        errors,
        values,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => (
        <>
          {console.log("errors", errors)}
          {loader && loading && <Loader />}
          <Row style={{ width: "100%", paddingInline: "10px" }}>
            {multilanguageinputs && (
              <MultiLanguageInput inputKeys={multilanguageinputs} />
            )}
            {inputs.map((key, i) => {
              const disabledvalue = key.disabled ? key.disabled(values) : false;
              return key.hide && key.hide(values) ? (
                <></>
              ) : (
                <GetInput
                  inputprop={key}
                  key={i}
                  disabledvalue={disabledvalue}
                />
              );
            })}
          </Row>
          <div
            className={
              "d-flex mt-2 " +
              (onHide ? "justify-content-between " : "justify-content-end ")
            }
          >
            {onHide && (
              <Button
                color="danger"
                type="submit"
                onClick={() => {
                  return onHide();
                }}
              >
                {t("Close")}
              </Button>
            )}
            <Button
              color="success"
              type="submit"
              onClick={() => handleSubmit()}
            >
              <i className="bx bx-plus" />
              {t("Save")}
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};

export default CustomFormik;
