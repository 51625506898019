import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
import Icon from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const menuList: {
    title: string;
    icon?: string;
    link: string;
    auth?: string[];
    submenu?: {
      title: string;
      icon?: string;
      link: string;
      auth?: string[];
    }[];
  }[] = [
    {
      title: props.t("Dashboard"),
      icon: "home",
      link: "/dashboard",
    },

    {
      title: props.t("Campaigns"),
      icon: "award",
      link: "/campaigns",
    },
    {
      title: props.t("Privileges"),
      icon: "star",
      link: "/privileges",
    },
    {
      title: props.t("Coupon Check"),
      icon: "check-circle",
      link: "/coupon-check",
    },
    {
      title: props.t("Used Codes"),
      icon: "trash",
      link: "/used-codes",
    },
  ];
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            {menuList.map(x => (
              <>
                {x.submenu ? (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      {x.icon && (
                        <Icon
                          //@ts-ignore
                          name={x.icon}
                        />
                      )}
                      <span>{x.title}</span>
                    </Link>
                    <ul className="sub-menu">
                      {x.submenu?.map(sub => (
                        <>
                          <li>
                            <Link to={sub.link}>{sub.title}</Link>
                          </li>
                        </>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li>
                    <Link to={x.link} className="">
                      {x.icon && (
                        <Icon
                          //@ts-ignore
                          name={x.icon}
                        />
                      )}
                      <span>{x.title}</span>
                    </Link>
                  </li>
                )}
              </>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
