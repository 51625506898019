import { ReactDatePickerProps } from "react-datepicker";
import { DrawerProps, SelectPickerProps } from "rsuite";

export interface Props {
  label: string;
  field: string;
  checkedLabel?: string;
  uncheckedLabel?: string;
  error?: any;
  disabled?: boolean;
  type?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  touched?: any;
}
export interface RadioGrupProps {
  label: string;
  field: string;
  data: any[];
  error?: any;
  disabled?: boolean;
  labelKey: string;
  valueKey: string;
  type?: string;
  onChange?: (e: any, item?: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  touched?: any;
}

export interface CustomFormikProps {
  onSubmit: (values: any) => void;
  inputs: inputKey[];
  multilanguageinputs?: inputKey[];
  validationSchema: any;
  initialValues: any;
  loader?: boolean;
  onHide?: () => void;
}

export interface CustomModalFormProps extends CustomFormikProps {
  modalProps: CustomModalProps;
}
export type inputKey = {
  hide?: (values: any) => boolean;
  field: string;
  label: string;
  disabled?: (values: any) => boolean;
  type?: string;
  col?: any;
  fileUpload?: {
    multiple: boolean;
    title: string;
    accept?: string | string[];
    linkField?: string;
  };
  checkedLabel?: string;
  uncheckedLabel?: string;
  inputType?: InputType;
  lookup?: SelectPickerProps<any>;
  radiogrups?: {
    data: any[];
    labelKey: string;
    valueKey: string;
  };
  googlemap?: {
    latField: string;
    lonField: string;
  };
  dependedField?: string;
  onChange?: (value?: any, item?: any, setFieldValue?: any) => void;
};
export interface CustomModalProps extends DrawerProps {
  title?: any;
  onHide: () => void;
  outsideClick?: boolean;
}

export interface CustomSelectPickerProps extends SelectPickerProps<any> {
  label: string;
  field: string;
  error?: any;
  touched?: any;
}

export interface CustomDatepickerProps extends ReactDatePickerProps<any> {
  label: string;
  field: string;
  error?: any;
  touched?: any;
}

export enum InputType {
  text = 1,
  number = 2,
  multiselect = 3,
  editor = 4,
  checkbox = 5,
  date = 6,
  datetime = 7,
  fileUpload = 8,
  multilinetext = 9,
  currency = 10,
  radiogrup = 11,
  toogle = 12,
  googlemap = 13,
}

export const FindInputType = (id: number) => {
  switch (id) {
    case 1:
      return InputType.text;
    case 2:
      return InputType.number;
    case 3:
      return InputType.multiselect;
    case 4:
      return InputType.editor;
    case 5:
      return InputType.checkbox;
    case 6:
      return InputType.date;
    case 7:
      return InputType.datetime;
    case 8:
      return InputType.fileUpload;
    case 9:
      return InputType.multilinetext;
    case 10:
      return InputType.currency;
    case 12:
      return InputType.radiogrup;
  }
};
