import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import ResetPassword from "src/pages/Authentication/ResetPassword";
import ConfirmMail from "src/pages/Authentication/ConfirmMail";
import Error404 from "src/pages/Authentication/Error404";
import OpportunityPage from "src/pages/Privileges";
import CampaignPage from "src/pages/Campaigns";
import CouponCheckPage from "src/pages/CouponCheck";
import CampaignCodesPage from "src/pages/CampaingCoupons";
import UsedCodesPage from "src/pages/UsedCodes";
import CouponTransactionPage from "src/pages/CouponTransaction";

interface RouteProps {
  path: string;
  component: any;
  roles?: string[];
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/campaigns",
    component: CampaignPage,
  },
  {
    path: "/campaign-codes/:id",
    component: CampaignCodesPage,
  },
  {
    path: "/used-codes",
    component: UsedCodesPage,
  },
  {
    path: "/coupon-transactions/:id",
    component: CouponTransactionPage,
  },
  {
    path: "/privileges",
    component: OpportunityPage,
  },
  {
    path: "/coupon-check",
    component: CouponCheckPage,
  },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes: Array<RouteProps> = [
  { path: "/login", component: Login },
  { path: "/404", component: Error404 },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/confirmemail/:userId", component: ConfirmMail },
];

export { userRoutes, authRoutes };
