import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UsedCodeDto } from "src/store/campaigns/type";
import MainPage from "src/components/PageContent/MainPage";
import { getUsedCodes } from "src/store/campaigns/saga";
import { getLocalDate } from "src/helpers/getLocalDate";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useHistory } from "react-router";
const UsedCodesPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<UsedCodeDto[]>([]);
  const history = useHistory();
  const columns = [
    {
      text: t("Privilege"),
      dataField: "PrivilegeTitle",
    },
    {
      text: t("Customer"),
      dataField: "CustomerName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },
    {
      text: t("QRCode"),
      dataField: "QRCode",
    },
    {
      text: t("Validated"),
      dataField: "Validated",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("ValidatedDate"),
      dataField: "ValidatedDate",
      formatter: (cellContent: any) => getLocalDate(cellContent),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#2ab57d",
              text: t("Transactions"),
            }}
            onClick={() => {
              history.push("/coupon-transactions/" + cellContent);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <MainPage
        title={t("Used Codes")}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getUsedCodes,
        }}
      />
    </React.Fragment>
  );
};

export default UsedCodesPage;
