import CustomDrawer from "../../Modal/drawer";
import CustomFormik from "../Formik";
import { CustomModalFormProps } from "../type";

const FormModal = ({ modalProps, ...rest }: CustomModalFormProps) => {
  return (
    <CustomDrawer {...modalProps}>
      <CustomFormik {...rest} />
    </CustomDrawer>
  );
};
export default FormModal;
