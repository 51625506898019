import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { userSelector } from "src/store/auth";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
  roles?: string[];
}

const Authmiddleware = ({
  component,
  roles,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;
  const user = useSelector(userSelector);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        // if (user && roles) {
        //   if (!user.Roles.some(x => roles.includes(x))) {
        //     return (
        //       <Redirect
        //         to={{ pathname: "/404", state: { from: props.location } }}
        //       />
        //     );
        //   }
        // }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
