import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Alert, Button, Col } from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import { sendUseCampaignCoupon } from "src/store/campaigns/saga";
import { CouponCampaignDto } from "src/store/campaigns/type";
import loader from "src/store/loader";
import CreateCouponTransaction from "../CouponTransaction/create";
import CustomerCard from "./card-customer";
import PrivilegeCard from "./card-privilege";
import VehicleCard from "./card-vehicle";

const UseCoupon = ({
  setCampaing,
  setValues,
  dataValues,
  campaign,
}: {
  campaign: CouponCampaignDto;
  dataValues: { code: string; plate: string };
  setValues: (data?: { code: string; plate: string }) => void;
  setCampaing: (campaing?: CouponCampaignDto) => void;
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [show, setShow] = useState<boolean>(false);
  const [hideButton, setHideButton] = useState(false);
  const [info, setInfo] = useState<string>();
  const history = useHistory();
  const { t } = useTranslation();
  const [showTransaction, setShowTransaction] = useState(false);
  return (
    <React.Fragment>
      <CreateCouponTransaction
        id={campaign.Id}
        onHide={() => setShowTransaction(false)}
        open={showTransaction}
        onSuccessClick={() =>
          history.push("/coupon-transactions/" + campaign.Id)
        }
      />
      <div className="d-flex  flex-column align-items-center w-100">
        <h2>Kupon Bilgileri</h2>
        <div className=" text-muted text-center">
          Mevcut Kupon Kodu : <span className="fw-bold">{dataValues.code}</span>
        </div>
        {error && (
          <Col md={4} className="mt-2">
            <Alert color="danger" className="text-center">
              {t(error)}
            </Alert>
          </Col>
        )}
        {hideButton && info && (
          <Col md={4} className="mt-2">
            <Alert color="success" className="text-center">
              {info}
            </Alert>
          </Col>
        )}
        {!hideButton && (
          <div className="my-2">
            <Button
              style={{ width: 200, marginRight: 10 }}
              color="danger"
              type="submit"
              onClick={() => {
                dispatch(loader.actions.showLoader());
                setCampaing(undefined);
                setValues(undefined);
                setInfo("");
                dispatch(loader.actions.hideLoader());
              }}
            >
              {"Geri"}
            </Button>
            <Button
              style={{ width: 200 }}
              color="success"
              type="submit"
              onClick={() => setShow(true)}
            >
              {"Kuponu Kullan"}
            </Button>
          </div>
        )}
        {show && (
          <CancelAlert
            message={`${dataValues.code} kodlu kuponu kullanmak istediğine emin misin?`}
            onConfirm={() => {
              dispatch(
                sendUseCampaignCoupon({
                  payload: {
                    onSuccess: (ms, payload) => {
                      setInfo("Kupon kullanıldı!");
                      setHideButton(true);
                      setShowTransaction(true);
                      return setShow(false);
                    },
                    onError: ms => {
                      setError(ms);
                      return setShow(false);
                    },
                  },
                  body: dataValues,
                })
              );
            }}
            onCancel={() => {
              return setShow(false);
            }}
          />
        )}
        {campaign.Customer && (
          <Col md={12}>
            <CustomerCard campaign={campaign} />
          </Col>
        )}
        {campaign.Privilege && (
          <Col md={12}>
            <PrivilegeCard campaign={campaign} />
          </Col>
        )}
        {campaign.Vehicle && (
          <Col md={12}>
            <VehicleCard campaign={campaign} />
          </Col>
        )}
      </div>
    </React.Fragment>
  );
};

export default UseCoupon;
