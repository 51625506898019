import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignCodeDto } from "src/store/campaigns/type";
import MainPage from "src/components/PageContent/MainPage";
import { getCampaignCodeList } from "src/store/campaigns/saga";
import { useParams } from "react-router";
import { getLocalDate } from "src/helpers/getLocalDate";
const CampaignCodesPage = () => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<CampaignCodeDto[]>([]);
  const columns = [
    {
      text: t("Privilege"),
      dataField: "PrivilegeTitle",
    },
    {
      text: t("Customer"),
      dataField: "CustomerName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },
    {
      text: t("QRCode"),
      dataField: "QRCode",
    },
    {
      text: t("Validated"),
      dataField: "Validated",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("ValidatedDate"),
      dataField: "ValidatedDate",
      formatter: (cellContent: any) => getLocalDate(cellContent),
    },
  ];
  return (
    <React.Fragment>
      <MainPage
        title={t("Kampanya Kodları")}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getCampaignCodeList,
          paramsid: id,
        }}
      />
    </React.Fragment>
  );
};

export default CampaignCodesPage;
