import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import auth from "./auth";
import languageRedux from "./languages";
import loader from "./loader";

//login
const rootReducer = combineReducers({
  // public
  Layout,
  auth: auth.reducers,
  language: languageRedux.reducers,
  loader: loader.reducers,
});

export default rootReducer;
