import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams } from "../base/request/post";
import { BodyType } from "../base/request/request";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CampaignCodeDto,
  CampaignDto,
  CouponCampaignDto,
  UsedCodeDto,
} from "./type";

export const getCampaignList = createAction(
  "GET_CAMPAIGN_LIST",
  ({ payload, params }: SagaActionParams<CampaignDto[]>) => ({
    payload,
    url: "/api/supplierportals/campaigns" + (params ? params : ""),
  })
);
export const getUsedCodes = createAction(
  "GET_USED_CODES",
  ({ payload, params }: SagaActionParams<UsedCodeDto[]>) => ({
    payload,
    url: "/api/supplierportals/getvalidatedcodelists" + (params ? params : ""),
  })
);
export const getCampaignCodeList = createAction(
  "GET_CAMPAIGN_CODE_LIST",
  ({ payload, params, id }: SagaActionParams<CampaignCodeDto[]>) => ({
    payload,
    url:
      "/api/supplierportals/GetCampaignsCodeLists/" +
      id +
      (params ? params : ""),
  })
);
export const checkCoupon = createAction(
  "CHECK_COUPON",
  ({ payload, body }: SagaActionParams<CouponCampaignDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/supplierportals/checkcampaigncode",
  })
);
export const sendUseCampaignCoupon = createAction(
  "USE_CAMPAIGN_COUPON",
  ({ payload, body }: SagaActionParams<CouponCampaignDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/supplierportals/usecampaigncode",
  })
);

export const checkCouponWithGsm = createAction(
  "CHECK_COUPON_WITH_GSM",
  ({ payload, body }: SagaActionParams<CouponCampaignDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/supplierportals/checkcampaigncodewithgsm",
  })
);

export const sendUseCampaignCouponWithGsm = createAction(
  "USE_CAMPAIGN_COUPON_WITH_GSM",
  ({ payload, body }: SagaActionParams<CouponCampaignDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/supplierportals/usecampaigncodewithgsm",
  })
);
export const getCouponDetails = createAction(
  "GET_COUPON_DETAILS",
  ({ payload, id }: SagaActionParams<CampaignCodeDto>) => ({
    payload,
    url: "/api/supplierportals/getcoupondetail/" + id,
  })
);
const campaignsSaga = [
  takeLatest(
    getCouponDetails.toString(),
    (payload: SagaGenericParams<CampaignCodeDto>) =>
      getListSaga<CampaignCodeDto>(payload)
  ),
  takeLatest(
    getUsedCodes.toString(),
    (payload: SagaGenericParams<UsedCodeDto[]>) =>
      getListSaga<UsedCodeDto[]>(payload)
  ),
  takeLatest(
    getCampaignList.toString(),
    (payload: SagaGenericParams<CampaignDto[]>) =>
      getListSaga<CampaignDto[]>(payload)
  ),
  takeLatest(
    getCampaignCodeList.toString(),
    (payload: SagaGenericParams<CampaignDto[]>) =>
      getListSaga<CampaignDto[]>(payload)
  ),
  takeLatest(
    checkCoupon.toString(),
    (payload: PostSagaGenericParams<CouponCampaignDto[]>) =>
      postSaga<CouponCampaignDto[]>(payload)
  ),
  takeLatest(
    sendUseCampaignCoupon.toString(),
    (payload: PostSagaGenericParams<CouponCampaignDto>) =>
      postSaga<CouponCampaignDto>(payload)
  ),
  takeLatest(
    checkCouponWithGsm.toString(),
    (payload: PostSagaGenericParams<CouponCampaignDto[]>) =>
      postSaga<CouponCampaignDto[]>(payload)
  ),
  takeLatest(
    sendUseCampaignCouponWithGsm.toString(),
    (payload: PostSagaGenericParams<CouponCampaignDto>) =>
      postSaga<CouponCampaignDto>(payload)
  ),
];

export default campaignsSaga;
