import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivilegeDto } from "src/store/privilege/type";
import MainPage from "src/components/PageContent/MainPage";
import { getPrivilegeList } from "src/store/privilege/saga";
import moment from "moment";

const PrivilegePage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PrivilegeDto[]>([]);
  const columns = [
    {
      text: t("Privilege Category Name"),
      dataField: "PrivilegeCategoryName",
    },
    {
      text: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Start Date"),
      dataField: "StartDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: t("End Date"),
      dataField: "EndDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },

    {
      text: t("Coupon Count"),
      dataField: "CouponCount",
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        title={t("PrivilegeTitle")}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getPrivilegeList,
        }}
      />
    </React.Fragment>
  );
};

export default PrivilegePage;
