import React from "react";
import { DataTableWithPaginationProps } from "./type";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTableWithPagination from "./DataGrid";

const MainPage = ({
  gridProps,
  title,
  hidebreadcrumb,
}: {
  hidebreadcrumb?: boolean;
  title?: string;
  gridProps: DataTableWithPaginationProps;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>
            {title} {t("| Otoplan Talep Yönetim Sistemi")}
          </title>
        </MetaTags>
        <Container fluid>
          <DataTableWithPagination {...gridProps} title={title} />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default MainPage;
