import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignDto } from "src/store/campaigns/type";
import MainPage from "src/components/PageContent/MainPage";
import { getCampaignList } from "src/store/campaigns/saga";
import moment from "moment";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useHistory } from "react-router";
const CampaignPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<CampaignDto[]>([]);
  const columns = [
    {
      text: t("Category Name"),
      dataField: "PrivilegeCategoryName",
    },
    {
      text: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Start Date"),
      dataField: "StartDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: t("End Date"),
      dataField: "EndDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },

    {
      text: t("Coupon Count"),
      dataField: "CouponCount",
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#2ab57d",
              text: t("Codes"),
            }}
            onClick={() => {
              history.push("/campaign-codes/" + cellContent);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <MainPage
        title={t("CampaignTitle")}
        gridProps={{
          listData: data,
          columns: columns,

          setDataList: setData,
          action: getCampaignList,
        }}
      />
    </React.Fragment>
  );
};

export default CampaignPage;
