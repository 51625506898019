import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { BodyType } from "../base/request/request";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CouponTransactionDto, SupplierServiceTypeDto } from "./type";

export const getCouponTransactionList = createAction(
  "GET_COUPON_TRANSACTION_LIST",
  ({ payload, id, params }: SagaActionParams<CouponTransactionDto[]>) => ({
    payload,
    url:
      "/api/supplierportals/getcoupontransactions/" +
      id +
      (params ? params : ""),
  })
);
export const createCouponTransaction = createAction(
  "CREATE_COUPON_TRANSACTION",
  ({ payload, body }: SagaActionParams<CouponTransactionDto>) => ({
    payload,
    url: "/api/supplierportals/createcoupontransaction",
    body,
    bodyType: BodyType.raw,
  })
);
export const updateCouponTransaction = createAction(
  "UPDATE_COUPON_TRANSACTION",
  ({ payload, body }: SagaActionParams<CouponTransactionDto>) => ({
    payload,
    url: "/api/supplierportals/updatecoupontransaction",
    body,
    bodyType: BodyType.raw,
  })
);
export const getSupplierServiceTypes = createAction(
  "GET_SUPPLIER_SERVICE_TYPES",
  ({ payload }: SagaActionParams<SupplierServiceTypeDto[]>) => ({
    payload,
    url: "/api/supplierportals/getsupplierservicetype",
  })
);
const CouponTransactionSaga = [
  takeLatest(
    getSupplierServiceTypes.toString(),
    (payload: SagaGenericParams<SupplierServiceTypeDto[]>) =>
      getListSaga<SupplierServiceTypeDto[]>(payload)
  ),
  takeLatest(
    getCouponTransactionList.toString(),
    (payload: SagaGenericParams<CouponTransactionDto[]>) =>
      getListSaga<CouponTransactionDto[]>(payload)
  ),
  takeLatest(
    createCouponTransaction.toString(),
    (payload: PostSagaGenericParams<CouponTransactionDto>) =>
      postSaga<CouponTransactionDto>(payload)
  ),
  takeLatest(
    updateCouponTransaction.toString(),
    (payload: PostSagaGenericParams<CouponTransactionDto>) =>
      putSaga<CouponTransactionDto>(payload)
  ),
];

export default CouponTransactionSaga;
